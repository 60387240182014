import './Header.css'
export default function Header() {
  return (
    <div className="header">
      <a href="#become" className="border-right mh">REGISTER</a>
      <a href="https://t.me/+KWDqNIiLBRFlNzM1" target="_blank" rel="noreferrer" className="border-right mh">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
          <path d="M28.0488 5.77735L24.0879 24.457C23.7891 25.7754 23.0098 26.1035 21.9024 25.4824L15.8672 21.0352L12.9551 23.8359C12.6328 24.1582 12.3633 24.4277 11.7422 24.4277L12.1758 18.2813L23.3613 8.17383C23.8477 7.74024 23.2559 7.5 22.6055 7.9336L8.77736 16.6406L2.82424 14.7773C1.52932 14.373 1.50588 13.4824 3.09377 12.8613L26.3789 3.89063C27.4571 3.48633 28.4004 4.13086 28.0488 5.77735Z" fill="black"/>
        </svg>
      </a>
      <a href="https://www.foresightx.net" target="_blank" rel="noreferrer" className="foresightx border-right mb0">FORESIGHT X</a>
      <a href="#details" className="border-right mh">
        DETAILS
        <span>You should know</span>
      </a>
      <a href="#offer" className="border-right mh">
        OFFER
        <span>What we offer</span>
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_40_7642)">
          <path d="M4.75102 0.83543L4.57629 0.695648L4.41807 0.853871L2.69342 2.57852L2.49808 2.77386L2.71188 2.94879C6.07497 5.7004 9.45173 5.80488 11.91 5.08958L0.968769 16.0308L0.791992 16.2076L0.968769 16.3843L2.69342 18.109L2.87019 18.2858L3.04697 18.109L13.9882 7.16779C13.2729 9.62604 13.3774 13.0028 16.129 16.3659L16.3039 16.5797L16.4993 16.3843L18.2239 14.6597L18.3821 14.5015L18.2424 14.3267C15.558 10.9713 15.8887 6.59638 17.236 3.90189L17.3165 3.74072L17.1891 3.61331L15.4645 1.88866L15.3371 1.76125L15.1759 1.84183C12.4814 3.18907 8.10648 3.5198 4.75102 0.83543Z" fill="black" stroke="black" strokeWidth="0.5"/>
          </g>
          <defs>
          <clipPath id="clip0_40_7642">
          <rect width="19" height="19" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </a>
    </div>
  )
}