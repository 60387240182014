
import offerPic from './assets/offer.svg'

export default function Offer() {
  return (
    <div className="offer" id="offer">
      <img src={offerPic} alt="" />
      <div>
        <h3>
          <p>What</p> We Offer?
        </h3>
        <div className="offer-content">
          <div>
            <p>Complimentary Stay</p>
            <p>Free accommodation and co-working space are provided for our resident hackers, totaling approximately 20 people, from November 10th to November 20th.</p>
          </div>
          <div>
            <p>Learning</p>
            <p>Numerous robust brainstorming sessions, workshops, and panel discussions featuring sophisticated mentors and speakers.</p>
          </div>
          <div>
            <p>Networking</p>
            <p>A unique opportunity to connect with like-minded peers, builders, and industry leaders</p>
          </div>
          <div>
            <p>Bounty</p>
            <p>Stay tuned for the details</p>
          </div>
        </div>
      </div>
    </div>
  )
}