import faqPic from './assets/faq.svg'

import React, { useState } from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css'

const items = [
  {
    title: 'Will everyone who fills out the form receive free accommodation?',
    content: 'No, only around 20 hackers will be eligible for free accommodation due to limited space. We will conduct screening and announce the selected participants prior to the event.',
    uuid: 0,
  },
  {
    title: 'Do I have to purchase a ticket to participate in the hacker house?',
    content: 'No, there will be no charge for hackers attending our hacker house.',
    uuid: 1,
  },
  {
    title: 'When is the deadline for staying hacker applications？',
    content: 'Nov 5.',
    uuid: 2,
  },
  {
    title: 'Is there a resident group chat?',
    content: 'Yes, you can join our Telegram group to connect with fellow residents and stay updated on our hacker house: https://t.me/+KWDqNIiLBRFlNzM1.',
    uuid: 3,
  },
  {
    title: 'I\'m interested in becoming a speaker or partner for the hacker house. Who should I get in touch with?',
    content: 'Feel free to reach out to @luluisangry on Telegram or email us at foresightx2023@gmail.com.',
    uuid: 4,
  },
]

export default function Faq() {
  const [isExpanded, setIsExpanded] = useState(0)
  return (
    <div className="faq">
      <h3>FAQ</h3>
      <img src={faqPic} alt="" />
      <div>
        <div className="faq-content">
          <Accordion onChange={(ids) => setIsExpanded(ids[0])}>
            {items.map((item, k) => {
              return (<AccordionItem key={item.uuid} uuid={item.uuid} dangerouslySetExpanded={k === isExpanded}>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="faq-title">{item.title}</span>
                      </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="faq-text">{item.content}</p>
                  </AccordionItemPanel>
              </AccordionItem>)
            })}
          </Accordion>
        </div>
        
      </div>
    </div>
  )
}