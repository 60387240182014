export default function Functions() {
  return (
    <div className="function" id="details">
      <div>
        <span>👫</span>
        <h4>Social Relations</h4>
        <p>DID</p>
        <p>credit system</p>
        <p>governance</p>
        <p>...</p>
      </div>
      <div>
        <span>🎮</span>
        <h4>Live and Play</h4>
        <p>financial dapps</p>
        <p>gaming dapps</p>
        <p>intent, no-pain UX</p>
        <p>...</p>
      </div>
      <div>
        <span>🤖</span>
        <h4>Co-live with AI</h4>
        <p>AI based dapps</p>
        <p>ZKML</p>
        <p>decentralized compute</p>
        <p>...</p>
      </div>
      <div>
        <span>🌲</span>
        <h4>Public Goods</h4>
        <p>open source tools</p>
        <p>commonwealth</p>
        <p>sovereignty</p>
        <p>...</p>
      </div>
    </div>
  )
}