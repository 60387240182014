import gitcoin from './assets/gitcoin.svg'
import pse from './assets/pse.svg'
import ng from './assets/ng.svg'
import aspecta from './assets/aspecta.svg'
import berkeley from './assets/berkeley.svg'
import polygon from './assets/polygon.svg'
import openbuild from './assets/openbuild.svg'
import gitcoinBlack from './assets/gitcoin-black.svg'
import pseBlack from './assets/pse-black.svg'
import ngBlack from './assets/ng-black.svg'
import aspectaBlack from './assets/aspecta-black.svg'
import berkeleyBlack from './assets/berkeley-black.svg'
import polygonBlack from './assets/polygon-black.svg'
import openbuildBlack from './assets/openbuild-black.svg'
import animoca from './assets/animoca.svg'
import animocaBlack from './assets/animoca-black.svg'
import tioga from './assets/tioga.svg'
import tiogaBlack from './assets/tioga-black.svg'
import dojo from './assets/dojo.svg'
import dojoBlack from './assets/dojo-black.svg'
import orb from './assets/orb.svg'
import orbBlack from './assets/orb-black.svg'
import publicPic from './assets/public.svg'
import publicBlack from './assets/public-black.svg'
import p1b from './assets/p1b.svg'
import p1 from './assets/p1.svg'
import p2b from './assets/p2b.svg'
import p2 from './assets/p2.svg'
import p3 from './assets/p3.svg'
import p3b from './assets/p3b.svg'
import p4 from './assets/p4.svg'
import p4b from './assets/p4b.svg'
import p5 from './assets/p5.svg'
import p5b from './assets/p5b.svg'
import p6 from './assets/p6.svg'
import p6b from './assets/p6b.svg'
import p7 from './assets/p7.svg'
import p7b from './assets/p7b.svg'
// import p8 from './assets/p8.svg'
// import p8b from './assets/p8b.svg'
import p9 from './assets/p9.svg'
import p9b from './assets/p9b.svg'
import p10 from './assets/p10.svg'
import p10b from './assets/p10b.svg'
import p11 from './assets/p11.svg'
import p11b from './assets/p11b.svg'
import p12 from './assets/p12.svg'
import p12b from './assets/p12b.svg'
import p13 from './assets/p13.svg'
import p13b from './assets/p13b.svg'
import p14 from './assets/p14.svg'
import p14b from './assets/p14b.svg'
import p15 from './assets/p15.svg'
import p15b from './assets/p15b.svg'
import p16 from './assets/p16.svg'
import p16b from './assets/p16b.svg'
import p17 from './assets/p17.svg'
import p17b from './assets/p17b.svg'
import p18 from './assets/p18.svg'
import p18b from './assets/p18b.svg'
import p19 from './assets/p19.svg'
import p19b from './assets/p19b.svg'
import p20 from './assets/p20.svg'
import p20b from './assets/p20b.svg'

export default function Partners () {
  return (
    <div className="partners">
      <h3>Partners and Sponsors</h3>
      <div className='partners-content'>
        <div>
          <img src={gitcoin} alt="" />
          <img src={gitcoinBlack} alt="" />
          <p>Gitcoin</p>
        </div>
        <div>
          <img src={pse} alt="" />
          <img src={pseBlack} alt="" />
          <p>PSE</p>
        </div>
        <div>
          <img src={ng} alt="" />
          <img src={ngBlack} alt="" />
          <p>Node Guardians</p>
        </div>
        <div>
          <img src={aspecta} alt="" />
          <img src={aspectaBlack} alt="" />
          <p>Aspecta</p>
        </div>
        <div>
          <img src={berkeley} alt="" />
          <img src={berkeleyBlack} alt="" />
          <p>Morphism</p>
        </div>
        <div>
          <img src={polygon} alt="" />
          <img src={polygonBlack} alt="" />
          <p>Polygon</p>
        </div>
        <div>
          <img src={openbuild} alt="" />
          <img src={openbuildBlack} alt="" />
          <p>OpenBuild</p>
        </div>
        <div>
          <img src={animoca} alt="" />
          <img src={animocaBlack} alt="" />
          <p>Animoca</p>
        </div>
        <div>
          
          <img src={tiogaBlack} alt="" />
          <img src={tioga} alt="" />
          <p>Tioga Capital</p>
        </div>
        <div>
          <img src={dojoBlack} alt="" />
          <img src={dojo} alt="" />
          <p>Dojo</p>
        </div>
        <div>
         
          <img src={orbBlack} alt="" />
          <img src={orb} alt="" />
          <p>Orb</p>
        </div>
        <div>
          <img src={publicBlack} alt="" />
          <img src={publicPic} alt="" />
          <p>Public Works</p>
        </div>
        <div>
          <img src={p1b} alt="" />
          <img src={p1} alt="" />
          <p>Protocol Labs</p>
        </div>
        <div>
          <img src={p2b} alt="" />
          <img src={p2} alt="" />
          <p>Eth Storage</p>
        </div>
        <div>
          <img src={p3} alt="" />
          <img src={p3b} alt="" />
          <p>Mocaverse</p>
        </div>
        <div>
          <img src={p4} alt="" />
          <img src={p4b} alt="" />
          <p>Phaver</p>
        </div>
        <div>
          <img src={p5} alt="" />
          <img src={p5b} alt="" />
          <p>Lens Protocol</p>
        </div>
        <div>
          <img src={p6} alt="" />
          <img src={p6b} alt="" />
          <p>AW Research</p>
        </div>
        <div>
          <img src={p7} alt="" />
          <img src={p7b} alt="" />
          <p>ARPA</p>
        </div>
        <div>
          <img src={p9} alt="" />
          <img src={p9b} alt="" />
          <p>zkSync</p>
        </div>
        <div>
          <img src={p10} alt="" />
          <img src={p10b} alt="" />
          <p>Consensys</p>
        </div>
        <div>
          <img src={p11} alt="" />
          <img src={p11b} alt="" />
          <p>LambdaClass</p>
        </div>
        <div>
          <img src={p12} alt="" />
          <img src={p12b} alt="" />
          <p>Tonup</p>
        </div>
        <div>
          <img src={p13} alt="" />
          <img src={p13b} alt="" />
          <p>INT3FACE</p>
        </div>
        <div>
          <img src={p14} alt="" />
          <img src={p14b} alt="" />
          <p>Chainbase</p>
        </div>
        <div>
          <img src={p15} alt="" />
          <img src={p15b} alt="" />
          <p>Briq</p>
        </div>
        <div>
          <img src={p16} alt="" />
          <img src={p16b} alt="" />
          <p>Uniswap</p>
        </div>
        <div>
          <img src={p17} alt="" />
          <img src={p17b} alt="" />
          <p>Polyhedra</p>
        </div>
        <div>
          <img src={p18} alt="" />
          <img src={p18b} alt="" />
          <p>Hyper Oracle</p>
        </div>
        <div>
          <img src={p19} alt="" />
          <img src={p19b} alt="" />
          <p>DappOS</p>
        </div>
        <div>
          <img src={p20} alt="" />
          <img src={p20b} alt="" />
          <p>Space ID</p>
        </div>
      </div>
    </div>
  )
}