import Speaker0 from './assets/speaker0.png'
import Speaker1 from './assets/speaker1.png'
import Speaker2 from './assets/speaker2.png'
import Speaker3 from './assets/speaker3.png'
import Speaker4 from './assets/speaker4.png'
import Speaker5 from './assets/speaker5.png'
import Speaker6 from './assets/speaker6.png'
import Speaker7 from './assets/speaker7.png'
import Speaker8 from './assets/speaker8.png'
import Speaker9 from './assets/speaker9.png'
import Speaker10 from './assets/speaker10.png'
import Speaker11 from './assets/speaker11.png'
import Speaker12 from './assets/speaker12.png'
import Speaker13 from './assets/speaker13.jpeg'
import Speaker14 from './assets/speaker14.png'
import Speaker15 from './assets/speaker15.png'
import Speaker16 from './assets/speaker16.png'
import Speaker17 from './assets/speaker17.png'
import Speaker18 from './assets/speaker18.png'
import Speaker19 from './assets/speaker19.jpeg'
import Speaker20 from './assets/speaker20.png'
import Speaker21 from './assets/speaker21.jpeg'
import Speaker22 from './assets/speaker22.jpeg'
import Speaker23 from './assets/speaker23.png'
import Speaker24 from './assets/speaker24.png'
import Speaker25 from './assets/speaker25.png'
import Speaker26 from './assets/speaker26.png'
import Speaker27 from './assets/speaker27.png'
import SpeakerBg from './assets/speaker-bg.svg'

const data = [
  {
    name: 'Forest Bai',
    info: 'Co-founder of Foresight Ventures',
    img: Speaker0,
  },
  {
    name: 'Dr. Cathie So',
    info: 'ZKML researcher of EF PSE',
    img: Speaker1,
  },
  {
    name: 'Sam Benyakoub',
    info: 'Archmage of Node Guardians',
    img: Speaker2,
  },
  {
    name: 'Kimmo Sirén',
    info: 'Co-Founder of orb',
    img: Speaker3,
  },
  {
    name: 'Scott Moore',
    info: 'Founder of Gitcoin & Public Works',
    img: Speaker4,
  },
  {
    name: 'Jason Morton',
    info: 'Cofounder and CEO of Zkonduit',
    img: Speaker5,
  },
  {
    name: 'Tarrence',
    info: 'Cofounder of Cartridge and Core Developer at Dojo',
    img: Speaker6,
  },
  {
    name: 'Lacey Wisdom',
    info: 'Investements Principal at Protocol Labs',
    img: Speaker7,
  },
  {
    name: 'Zhou Qi',
    info: 'Founder of EthStorage',
    img: Speaker8,
  },
  {
    name: 'Kenneth Shek ',
    info: 'Head of Projects at Animoca & Mocaverse Lead',
    img: Speaker9,
  },
  {
    name: 'Joonatan',
    info: 'CEO and Cofounder of Phaver',
    img: Speaker10,
  },
  {
    name: 'Nader Dabit',
    info: 'Director of Developer Relations at AAVE & Lens Protocol',
    img: Speaker11,
  },
  {
    name: 'Cecilia',
    info: 'Cofounder & CEO of Morphism',
    img: Speaker12,
  },
  {
    name: 'Raphael Hannaert',
    info: 'Senior Investment Associate of Tioga Capital',
    img: Speaker13,
  },
  {
    name: 'RJ',
    info: 'Core Contributor at Lambdaclass / Founder at Grindlabs',
    img: Speaker14,
  },
  {
    name: 'Sylve',
    info: 'Cofounder briq & Dojo',
    img: Speaker15,
  },
  {
    name: 'Jessy',
    info: 'Developer Ecosystem Lead at EigenLayer',
    img: Speaker16,
  },
  {
    name: 'Riley Campbell',
    info: 'Software Engineer, Uniswap Labs',
    img: Speaker17,
  },
  {
    name: 'Mirko Garozzo',
    info: 'Dev Evangelist @ Consensys',
    img: Speaker18,
  },
  {
    name: 'Estevan Villar',
    info: 'Community Manager',
    img: Speaker19,
  },
  {
    name: 'Azeem',
    info: 'Head of Impact at Gitcoin',
    img: Speaker20,
  },
  {
    name: 'Ethan Francis',
    info: 'Developer Relations at Particle Network',
    img: Speaker21,
  },
  {
    name: 'Daidai',
    info: 'DevRel Lead at Chainbase',
    img: Speaker22,
  },
  {
    name: 'Angela',
    info: 'Developer Relations',
    img: Speaker23,
  },
  {
    name: 'Jerry',
    info: 'Founder at Tonup',
    img: Speaker24,
  },
  {
    name: 'Jana Bertram',
    info: 'Head of Strategy at the Rari Foundation',
    img: Speaker25,
  },
  {
    name: 'Taylor',
    info: 'DappOS Head of Community',
    img: Speaker26,
  },
  {
    name: 'Solo',
    info: 'Head of Growth of spaceID',
    img: Speaker27,
  },
]

export default function Speakers() {
  return (
    <div className="speakers">
      <h3>Speakers</h3>
      <img src={SpeakerBg} alt="" />
      <div className="speakers-content">
        {data.map((i, k) => 
          <div key={k}>
            <div className="speakers-img">
              <img src={i.img} alt="" />
            </div>
            <div className="speakers-text">
              <h6>{i.name}</h6>
              <p>{i.info}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}