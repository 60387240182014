import './App.css';
import Header from './Header'
import Functions from './Functions'
import Venue from './Venue'
import Offer from './Offer'
import Speakers from './Speakers'
import Partners from './Partners'
import Faq from './Faq'
import Summi from './Summi'

import onboardPic from './assets/banner-p1.png'
import buildPic1 from './assets/build-p1.svg'
import buildPic2 from './assets/build-p2.svg'
import buildPic3 from './assets/build-p3.svg'
import buildPic3Black from './assets/build-p3-black.svg'

import Marquee from "react-fast-marquee"

function App() {
  return (
    <div className="App">
      <Header />
      <div className="onboard">
        <div className="onboard-time">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.06982 20.26C2.06982 30.175 10.1098 38.215 20.0248 38.215C29.9398 38.215 37.9798 30.175 37.9798 20.26C37.9798 10.345 29.9398 2.305 20.0248 2.305C10.1098 2.305 2.06982 10.345 2.06982 20.26Z" fill="#FF6200"/>
            <path d="M11.5801 26.785L24.8651 37.615C29.7901 36.26 35.4901 32.3 37.5051 24.385L28.4601 15.595L26.4901 14.1L27.0551 17.265L27.2351 27.49L19.2751 27.8L13.1251 27.235L11.5801 26.785Z" fill="#E34800"/>
            <path d="M21.7151 20.56H23.9451C24.4001 20.56 24.7701 20.195 24.7701 19.75C24.7701 19.3 24.4001 18.94 23.9451 18.94H21.7151C21.2601 18.94 20.8901 19.305 20.8901 19.75C20.8901 20.195 21.2601 20.56 21.7151 20.56Z" fill="black"/>
            <path d="M25.9701 13.64H24.1501V12.46C24.1501 12.105 23.7951 11.815 23.3601 11.815C22.9251 11.815 22.5701 12.105 22.5701 12.46V13.64H17.4201V12.505C17.4201 12.15 17.0651 11.86 16.6301 11.86C16.1951 11.86 15.8401 12.15 15.8401 12.505V13.64H14.0801C12.7001 13.64 11.5801 14.615 11.5801 15.81V26.54C11.5801 27.735 12.7001 28.71 14.0801 28.71H25.9751C27.3551 28.71 28.4751 27.735 28.4751 26.54V15.805C28.4701 14.61 27.3501 13.64 25.9701 13.64ZM26.7401 26.54C26.7401 26.75 26.4051 26.995 25.9701 26.995H14.0801C13.6451 26.995 13.3101 26.75 13.3101 26.54V15.805C13.3101 15.595 13.6451 15.35 14.0801 15.35H15.8401V16.205C15.8401 16.56 16.1951 16.85 16.6301 16.85C17.0651 16.85 17.4201 16.56 17.4201 16.205V15.35H22.5651V16.16C22.5651 16.515 22.9201 16.805 23.3551 16.805C23.7901 16.805 24.1451 16.515 24.1451 16.16V15.35H25.9651C26.4001 15.35 26.7351 15.595 26.7351 15.805L26.7401 26.54Z" fill="black"/>
            <path d="M23.9648 22.15H16.1048C15.6498 22.15 15.2798 22.515 15.2798 22.96C15.2798 23.41 15.6498 23.77 16.1048 23.77H23.9698C24.4248 23.77 24.7948 23.405 24.7948 22.96C24.7898 22.515 24.4198 22.15 23.9648 22.15Z" fill="black"/>
          </svg>
          <p>From Nov 10 to Nov 20</p>
        </div>
        <h2>
          Onboard To The Future
        </h2>
        <h2><span>A Hello Future! Hacker House</span> Decentralized Society </h2>
        <h2>Hacker House</h2>
        <p className="">A Hello Future! Hacker House</p>
        <img src={onboardPic} alt="" />
      </div>
      <div className="build">
        <img src={buildPic1} alt="" className="build-logo" />
        <img src={buildPic2} alt="" className="build-bg" />
        <h3 id="become" >Build and live in the future first</h3>
        <p className='text-one'>Hi, all the explorers. <span>Welcome to the Onboard To The Future Decentralized Society Hacker House hosted by Foresight X and OpenBuild. </span> </p>
        <p className='text-two'>This space is dedicated to bringing the vision of DeSoc (Decentralized Society) to life. It's not just about asset and data ownership, or autonomy, but about a new world to live in powered by blockchain, characterized by genuine openness and self-sovereignty. We encourage all hackers to build infrastructure and applications that envision social graphs, and interactions, and even help solve societal problems in a decentralized society with us in Istanbul.</p>
        <a className="be-button" href="https://openbuild.xyz/learn/challenges/73" target="_blank" rel="noreferrer">
          <img className="white" src={buildPic3} alt=""  />
          <img className="black" src={buildPic3Black} alt=""  />
        </a>
        <p className="supported-openBuild">Developer reputation program is supported by OpenBuild.</p>
      </div>
      <div className="hacker-text">
        <hr />
        <Marquee>
          Hacker House Tracks · Hacker House Tracks · Hacker House Tracks · Hacker House Tracks · Hacker House Track · Hacker House Track · 
        </Marquee>
        <hr />
      </div>
      <Functions />
      <Offer />
      <Venue />
      <Summi />
      <Speakers />
      <Partners />
      <Faq />
    </div>
  );
}

export default App;
